import { useMemo, useState } from 'react';
import { ICommonContextsProps } from '@ldp/common-contexts';
import { useSDMCartContext, useSDMCustomerContext } from '@ldp/sdm-contexts';
import { useCookieConsent, useWindowSize, useDetermineLocale } from '@/hooks';
import { trackEvent, trackEventTileAdClick, trackRender } from '@/utils/analytics/analytics-utils';

/**
 * Hook to retrieve the common contexts props.
 */
export const useCommonContextProps = () => {
  const locale = useDetermineLocale();
  const { currentDevice } = useWindowSize({ isModernized: true });

  const { cartCount, pageId } = useSDMCartContext();
  const { pcOptimumAccount } = useSDMCustomerContext();

  const isQuebec = useCookieConsent();
  const [componentError, setComponentError] = useState({ mastheadError: '' });

  /** The props necessary for the common contexts container */
  const commonContextProps = useMemo<ICommonContextsProps>(
    () => ({
      settings: {
        // ... map app settings data here as per the interface
        locale,
        env: process.env.NEXT_PUBLIC_VIEW_RESOLVER_ENV,
        isQuebec,
        banner: 'loblaw',
        sduiBaseUrl: 'https://dev-pcexpress.api.loblaw.digital/pcx-bff/api/v1',
        sduiApiKey: 'eZb87pkFiGAB7UAQbQbllgt7WAAk10rf',
        cdnBaseUrl: 'https://assets-platform.loblaws.ca/ldp-core-web-dev/lower/ldp/',
        componentError,
        setComponentError,
      },
      analytics: {
        trackEvent: (type, context) => {
          const snowplowGlobalContextFields = { cartCount, locale, currentDevice, pageId, pcOptimumAccount };
          trackEvent(type, context, snowplowGlobalContextFields);
        },
        trackRender: (componentName, context) => {
          trackRender(componentName);
        },
        trackEventTileAdClick: (
          componentName: string,
          { isSponsored, productId }: { isSponsored: boolean; productId: string }
        ) => {
          trackEventTileAdClick(componentName, { isSponsored, productId });
        },
      },
      featureFlags: {
        // ... map feature flags data here as per the interface
        EnableTestFeature: true,
        EnableHeliosSnowplow: true,
      },
    }),
    [locale, currentDevice, cartCount, pageId, pcOptimumAccount, isQuebec, componentError]
  );

  return {
    locale,
    commonContextProps,
    cartCount,
    pageId,
    currentDevice,
    pcOptimumAccount,
    componentError,
  };
};

export default useCommonContextProps;
